import React from "react";
import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Media from "react-bootstrap/Media";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { columnStyle, logoStyle } from "./Styles.js";

import dulaya from "../Images/dulaya.jpg";
import ProjectsLogo from "../Images/Projects-logo.png";
import PhoneLogo from "../Images/Phone-logo.jpg";
import JSlogo from "../Images/JS-logo.png";
import Reactlogo from "../Images/React-logo.png";
import TypeScriptlogo from "../Images/TypeScript.png";
import Reduxlogo from "../Images/Redux-logo.png";
import Nodelogo from "../Images/Node-logo.png";
import Gitlogo from "../Images/Git-logo.png";
import AWSlogo from "../Images/AWS-logo.png";
import HTMLlogo from "../Images/HTML-logo.png";
import SQLlogo from "../Images/SQL-logo.png";

import UserInterface from "../Images/UserInterface.png";
import LightBulblogo from "../Images/LightBulb.png";
import Monitorlogo from "../Images/Monitor-logo.jpg";

import Caplogo from "../Images/Cap-logo.jpg";
import ReactNativelogo from "../Images/ReactNative.png";
import CCPlogo from "../Images/CCP-logo.png";

import Webpack from "../Images/Webpack.png";
import Nginx from "../Images/Nginx.png";
import ReactNative from "../Images/ReactNative.png";

import { Contact } from "./Contact.js";
import "./home.css";

const Home = () => {
  //Open New Tab
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <Container>
        <Container
          horizontal="true"
          style={{ textAlign: "center", margin: "auto" }}
        >
          <Card
            style={{
              margin: "5px",
              padding: "10px",
              textAlign: "left",
              backgroundImage: "url('')"
            }}
          >
            <Media>
              <Col style={{ maxWidth: "175px", textAlign: "center"}}>
                <div id="image-wrapper">
                  <div id="image-border"></div>
                  <Image
                    className="mr-3"
                    variant="top"
                    src={dulaya}
                    roundedCircle
                    style={{ width: "150px" }}
                    id="image-dulaya"
                  />
                </div>
                <Card.Title style={{ width: "175px" }}>
                  Dulaya Saennok
                </Card.Title>
                <Card.Subtitle
                  className="mb-2 text-muted"
                  style={{ width: "175px" }}
                >
                  Software Developer
                </Card.Subtitle>
              </Col>
              <Media.Body style={{padding: '15px'}}>
                <Card.Text>
                  Software developer specializing in modern frontend development
                  using TypeScript and React. Looking to transition from junior
                  to mid-level. Currently working on a web application dashboard
                  for displaying financial data for personal project and
                  studying Java development.
                </Card.Text>
              </Media.Body>
            </Media>
          </Card>
        </Container>

        <div style={{ height: "50px" }}></div>

        <div id="i-beam">
          <div id="i-beam-top"></div>
          <div id="i-beam-center">
            <div>
              <div id="rivet"></div>
              <div id="rivet"></div>
            </div>
            <p>Professional Experience</p>
            <div>
              <div id="rivet"></div>
              <div id="rivet"></div>
            </div>
          </div>
          <div id="i-beam-top"></div>
        </div>
        <div style={{ margin: "50px" }}></div>
        <Container
          horizontal="true"
          style={{ textAlign: "center", margin: "auto" }}
        >
          {/* <Card
                style={{
                  color: "white",
                  margin: "5px",
                  padding: "10px",
                  textAlign: "center",
                  backgroundImage: `linear-gradient( 111.5deg, rgba(20,100,196,1) 0.4%, rgba(33,152,214,1) 100.2% )`,
                }}
              > */}
          {/* </Card> */}

          <Row style={{ float: "center" }}>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image
                  src={UserInterface}
                  rounded
                  style={{ maxHeight: "200px" }}
                />
              </ListGroup.Item>
              <ListGroup.Item>
                <p>UI Software Developer</p>
                <p>Quintrix Solutions</p>
                <p>July 2022 - December 2023</p>
              </ListGroup.Item>
            </Col>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image
                  src={Monitorlogo}
                  rounded
                  style={{ maxHeight: "200px" }}
                />
              </ListGroup.Item>
              <ListGroup.Item>
                <p>Self-Taught/Freelance Software Developer</p>
                <p>Feb. 2019 - June 2022</p>
              </ListGroup.Item>
            </Col>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image
                  src={LightBulblogo}
                  rounded
                  style={{ maxHeight: "200px" }}
                />
              </ListGroup.Item>
              <ListGroup.Item>
                <p>Professional experience in patent research and analysis.</p>
                <p>Sept. 2015 - Jan. 2019</p>
              </ListGroup.Item>
            </Col>
          </Row>

          <div style={{ height: "50px" }}></div>

          <div id="i-beam">
            <div id="i-beam-top"></div>
            <div id="i-beam-center">
              <div>
                <div id="rivet"></div>
                <div id="rivet"></div>
              </div>
              <p>Education</p>
              <div>
                <div id="rivet"></div>
                <div id="rivet"></div>
              </div>
            </div>
            <div id="i-beam-top"></div>
          </div>
          <div style={{ margin: "50px" }}></div>

          <Row style={{ float: "center" }}>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image src={Caplogo} rounded style={{ maxHeight: "200px" }} />
              </ListGroup.Item>
              <ListGroup.Item>
                <p>Bsc in Mechanical Engineering</p>
                <p>University of Nevada, Las Vegas</p>
                <p>Class of 2015</p>
              </ListGroup.Item>
            </Col>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image
                  src={ReactNativelogo}
                  rounded
                  style={{ maxHeight: "200px" }}
                />
              </ListGroup.Item>
              <ListGroup.Item>
                <p>React Native Training</p>
                <p>Quintrix Solutions</p>
                <p>February 2022 - May 2022</p>
              </ListGroup.Item>
            </Col>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image src={CCPlogo} rounded style={{ maxHeight: "150px" }} />
              </ListGroup.Item>
              <ListGroup.Item>
                <p>AWS Certified Cloud Practitioner</p>
                <p>Expires: 01 March 2024</p>
                <p>Validation: WGF0E45BK11EQPKT</p>
                <p
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() =>
                    openInNewTab(
                      "https://www.youracclaim.com/badges/a445f528-c099-4c24-b238-b8878891fde6"
                    )
                  }
                >
                  Verify
                </p>
              </ListGroup.Item>
            </Col>
          </Row>

          <div style={{ height: "50px" }}></div>

          <div id="i-beam">
            <div id="i-beam-top"></div>
            <div id="i-beam-center">
              <div>
                <div id="rivet"></div>
                <div id="rivet"></div>
              </div>
              <div>
                <div id="rivet"></div>
                <div id="rivet"></div>
              </div>
            </div>
            <div id="i-beam-top"></div>
          </div>
          <div style={{ margin: "50px" }}></div>

          <Row style={{ float: "center" }}>
            <Col style={{ columnStyle }}>
              <Link to="/projects">
                <ListGroup.Item>
                  <Image
                    src={ProjectsLogo}
                    rounded
                    style={{ maxHeight: "150px" }}
                  />
                </ListGroup.Item>
                <ListGroup.Item>See My Projects</ListGroup.Item>
              </Link>
            </Col>
            <Col style={{ columnStyle }}>
              <Link to="/contact">
                <ListGroup.Item>
                  <Image
                    src={PhoneLogo}
                    rounded
                    style={{ maxHeight: "150px" }}
                  />
                </ListGroup.Item>
                <ListGroup.Item>Contact Me</ListGroup.Item>
              </Link>
            </Col>
          </Row>

          <div style={{ height: "50px" }}></div>

          <div id="i-beam">
            <div id="i-beam-top"></div>
            <div id="i-beam-center">
              <div>
                <div id="rivet"></div>
                <div id="rivet"></div>
              </div>
              <p>My Skills</p>
              <div>
                <div id="rivet"></div>
                <div id="rivet"></div>
              </div>
            </div>
            <div id="i-beam-top"></div>
          </div>
          <div style={{ margin: "50px" }}></div>

          <Row style={{ float: "center" }}>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image src={JSlogo} rounded style={logoStyle} />
              </ListGroup.Item>
              <ListGroup.Item>JavaScript</ListGroup.Item>
            </Col>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image src={Reactlogo} rounded style={logoStyle} />
              </ListGroup.Item>
              <ListGroup.Item>React</ListGroup.Item>
            </Col>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image src={TypeScriptlogo} rounded style={logoStyle} />
              </ListGroup.Item>
              <ListGroup.Item>TypeScript</ListGroup.Item>
            </Col>
          </Row>
          <Row style={{ float: "center" }}>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image src={Reduxlogo} rounded style={logoStyle} />
              </ListGroup.Item>
              <ListGroup.Item>Redux</ListGroup.Item>
            </Col>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image src={Nodelogo} rounded style={logoStyle} />
              </ListGroup.Item>
              <ListGroup.Item>Node.js</ListGroup.Item>
            </Col>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image src={Gitlogo} rounded style={logoStyle} />
              </ListGroup.Item>
              <ListGroup.Item>Git</ListGroup.Item>
            </Col>
          </Row>
          <Row style={{ float: "center" }}>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image src={AWSlogo} rounded style={logoStyle} />
              </ListGroup.Item>
              <ListGroup.Item>AWS</ListGroup.Item>
            </Col>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image src={HTMLlogo} rounded style={logoStyle} />
              </ListGroup.Item>
              <ListGroup.Item>HTML</ListGroup.Item>
            </Col>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image src={SQLlogo} rounded style={logoStyle} />
              </ListGroup.Item>
              <ListGroup.Item>SQL</ListGroup.Item>
            </Col>
          </Row>

          <Row style={{ float: "center" }}>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image src={Webpack} rounded style={logoStyle} />
              </ListGroup.Item>
              <ListGroup.Item>Webpack</ListGroup.Item>
            </Col>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image src={Nginx} rounded style={logoStyle} />
              </ListGroup.Item>
              <ListGroup.Item>Nginx</ListGroup.Item>
            </Col>
            <Col style={columnStyle}>
              <ListGroup.Item>
                <Image src={ReactNative} rounded style={logoStyle} />
              </ListGroup.Item>
              <ListGroup.Item>ReactNative</ListGroup.Item>
            </Col>
          </Row>
        </Container>

        <div style={{ height: "50px" }}></div>

        <div id="i-beam">
          <div id="i-beam-top"></div>
          <div id="i-beam-center">
            <div>
              <div id="rivet"></div>
              <div id="rivet"></div>
            </div>
            <p>Contact</p>
            <div>
              <div id="rivet"></div>
              <div id="rivet"></div>
            </div>
          </div>
          <div id="i-beam-top"></div>
        </div>
        <div style={{ margin: "50px" }}></div>
        <Row>
          <Col>
            <Contact />
          </Col>
        </Row>

        <div style={{ height: "100px" }}></div>
      </Container>
    </>
  );
};

export default Home;
