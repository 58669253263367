export const columnStyle = {
    margin: '5px',
}

export const logoStyle = {
    height: '65px',
}

export const logoStyleSmall = {
    maxHeight: '25px',
    margin: '5px'
}