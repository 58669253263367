import React from "react";

import {
  Carousel,
  Container,
  Row,
  Col,
  CardDeck,
  Card,
  Button,
  Image,
  ButtonGroup,
} from "react-bootstrap";
import { FaGithubAlt } from "react-icons/fa";

import { columnStyle, logoStyleSmall } from "./Styles.js";

import AWSlogo from "../Images/AWS-logo.png";
import JSlogo from "../Images/JS-logo.png";
import Reactlogo from "../Images/React-logo.png";
import Nodelogo from "../Images/Node-logo.png";
import Expresslogo from "../Images/Express-logo.png";
import MySQLlogo from "../Images/MySQL-logo.png";
import HTMLlogo from "../Images/HTML-logo.png";
import CSSlogo from "../Images/CSS-logo.png";

import MathChat1 from "../Images/MathChat-1.png";
import MathChat2 from "../Images/MathChat-2.png";
import MathChat3 from "../Images/MathChat-3.png";
import Currency from "../Images/Currency.png";

import TypeScript from "../Images/TypeScript.png";
import Webpack from "../Images/Webpack.png";
import Nginx from "../Images/Nginx.png";

const shadow = {
  firstShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
};

const imgStyle = {
  boxShadow: shadow.firstShadow,
  margin: "auto",
  //maxWidth: '750px'
};

const buttonStyle = {
  margin: "0 5px",
};

const Projects = () => {
  //Open New Tab
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const mathChat = [MathChat1, MathChat2, MathChat3];
  const currency = [Currency];

  return (
    <>
      <Container horizontal="true">
        <Card style={{ maxWidth: "750px", margin: "25px auto" }}>
          <Card.Title
            style={{
              color: "white",
              padding: "5px",
              textAlign: "center",
              background: "#0B60B0",
              cursor: "pointer",
            }}
            onClick={() => openInNewTab("https://dailycurrencyprices.com")}
          >
            Daily Currency Prices
          </Card.Title>
          <Carousel style={imgStyle}>
            {currency.map((picture) => (
              <Carousel.Item key={picture}>
                <img className="d-block w-100" src={picture} alt={picture} />
              </Carousel.Item>
            ))}
          </Carousel>
          <Card.Body
            style={{
              color: "white",
              textAlign: "center",
              background: "#0B60B0",
            }}
          >
            <Row style={{ textAlign: "center" }}>
              <Col style={{ maxWidth: "120px" }}>
                <ButtonGroup vertical>
                  <Button
                    variant="outline-light"
                    style={{}}
                    onClick={() =>
                      openInNewTab("https://dailycurrencyprices.com")
                    }
                  >
                    Demo
                  </Button>
                </ButtonGroup>
              </Col>
              <Col>
                <Card.Text>
                  <span
                    onClick={() =>
                      openInNewTab("https://dailycurrencyprices.com")
                    }
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    dailycurrencyprices.com
                  </span>
                  is a dashboard showing foreign currency prices.
                </Card.Text>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer style={{ textAlign: "center" }}>
            <Row>
              <Col style={columnStyle}>
              <Image src={TypeScript} rounded style={logoStyleSmall} />
                <Image src={JSlogo} rounded style={logoStyleSmall} />
                <Image src={Reactlogo} rounded style={logoStyleSmall} />
                <Image src={Webpack} rounded style={logoStyleSmall} />
                <Image src={Nginx} rounded style={logoStyleSmall} />
                <Image src={AWSlogo} rounded style={logoStyleSmall} />
                <Image src={Nodelogo} rounded style={logoStyleSmall} />

              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Container>

      <Container horizontal="true">
        <Card style={{ maxWidth: "750px", margin: "25px auto" }}>
          <Card.Title
            style={{
              color: "white",
              padding: "5px",
              textAlign: "center",
              backgroundImage:
                "radial-gradient(circle farthest-corner at 10% 20%, rgba(0, 95, 104, 1) 0%, rgba(15, 156, 168, 1) 90%)",
              cursor: "pointer",
            }}
            onClick={() => openInNewTab("https://mathchat.netlify.app")}
          >
            mαth chαt
          </Card.Title>
          <Carousel style={imgStyle}>
            {mathChat.map((picture) => (
              <Carousel.Item key={picture}>
                <img className="d-block w-100" src={picture} alt={picture} />
              </Carousel.Item>
            ))}
          </Carousel>
          <Card.Body
            style={{
              color: "white",
              textAlign: "center",
              backgroundImage:
                "radial-gradient(circle farthest-corner at 10% 20%, rgba(0, 95, 104, 1) 0%, rgba(15, 156, 168, 1) 90%)",
            }}
          >
            <Row style={{ textAlign: "center" }}>
              <Col style={{ maxWidth: "120px" }}>
                <ButtonGroup vertical>
                  <Button
                    variant="outline-light"
                    style={{}}
                    onClick={() => openInNewTab("https://mathchat.netlify.app")}
                  >
                    Demo
                  </Button>
                  <Button
                    variant="light"
                    style={{ border: "1px solid", borderColor: "white" }}
                    onClick={() =>
                      openInNewTab("https://github.com/Dulaya/mathchat")
                    }
                  >
                    <FaGithubAlt />
                  </Button>
                </ButtonGroup>
              </Col>
              <Col>
                <Card.Text>
                  mαth chαt (
                  <span
                    onClick={() => openInNewTab("https://mathchat.netlify.app")}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    mathchat.netlify.app
                  </span>
                  ) is a chat interface web app that help users with algebra
                  homework. Users can type into the input field on an algebra
                  topic such as Pythagorean Theorem. An interface will pop-up,
                  allowing users to input numbers for solving Pythagorean
                  Theorem.
                </Card.Text>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer style={{ textAlign: "center" }}>
            <Row>
              <Col style={columnStyle}>
                <Image src={JSlogo} rounded style={logoStyleSmall} />
                <Image src={Reactlogo} rounded style={logoStyleSmall} />
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
};

export default Projects;
